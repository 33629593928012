html {
  height: 100%;
}

body {
  height: 100%;
}

div#root {
  height: 100%;
}

.App {
  background: #F5F5F5;
  height: 100%;
  overflow: auto;
}

.App-header {
  padding: 20px;
  border-bottom: 2px solid #C41E3A;
}

.App-title {
  font-size: 1.5em;
  font-family: 'Russo One', sans-serif;
}

.sidebar-and-content {
  display: flex;
}

.sidebar {
  margin-top: 10px;
  padding-left: 10px;
  width: 175px;
}

div.sidebar a {
  text-decoration: none;
}

.side-item-container {
  color: black;
}
.side-item-selected {
  color: #F5F5F5;
  background: #C41E3A;
}

.side-item {
  color: inherit;
  padding: 10px 20px 10px 10px;
  -webkit-transition: background 0.5s;
  border-radius: 5px;
  background: inherit;
  margin-bottom: 1px;
  width: 165px;
}
.side-item:hover {
  background: #C41E3A;
  color: #F5F5F5;
}

.section-header {
  font-size: 1.5em;
  font-family: 'Russo One', sans-serif;
  margin-bottom: 10px;
}

.content-pane {
  width: 50%;
  margin: 10px;
  padding: 10px 20px 10px 20px;
}

.query-results {
  width: 18rem;
  margin: auto;
}

.query-results-header {
  background-color: #D3D3D3;
  padding: 10px 15px;
}
